var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useCallback } from "react";
import { post } from "../../utilities";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { socket } from "../../client-socket";
import SetName from "../modules/SetName";
import RoomSideBar from "../modules/RoomSideBar";
import RoomMain from "../modules/RoomMain";
var RoomPage = function (props) {
    var userId = props.userId;
    var _a = useState("waiting"), status = _a[0], setStatus = _a[1];
    var _b = useState(""), roomId = _b[0], setRoomId = _b[1];
    var _c = useState(""), levelName = _c[0], setLevelName = _c[1];
    var _d = useState(false), skipsAllowed = _d[0], setSkipsAllowed = _d[1];
    var _e = useState(new Date()), lastSkip = _e[0], setLastSkip = _e[1];
    var _f = useState([]), users = _f[0], setUsers = _f[1];
    var _g = useState([]), spectatingUsers = _g[0], setSpectatingUsers = _g[1];
    var _h = useState(false), spectatingModeOn = _h[0], setSpectatingModeOn = _h[1];
    var _j = useState(false), spectating = _j[0], setSpectating = _j[1];
    var _k = useState(undefined), question = _k[0], setQuestion = _k[1];
    var _l = useState([]), questionAnswers = _l[0], setQuestionAnswers = _l[1];
    var _m = useState(new Date()), startTime = _m[0], setStartTime = _m[1];
    var _o = useState(0), numSeconds = _o[0], setNumSeconds = _o[1];
    var _p = useState([]), scores = _p[0], setScores = _p[1];
    var _q = useState([]), messages = _q[0], setMessages = _q[1];
    var _r = useState({ oldRatings: new Map(), newRatings: new Map(), empty: true }), ratingUpdate = _r[0], setRatingUpdate = _r[1];
    var _s = useState(true), setNameOpen = _s[0], setSetNameOpen = _s[1];
    var _t = useState(false), rematchDisabled = _t[0], setRematchDisabled = _t[1];
    var joinRoomPage = function (joinAsSpectator) {
        if (!userId)
            return Promise.resolve();
        var body = {
            roomName: props.roomName,
            spectating: joinAsSpectator,
        };
        setSpectatingModeOn(joinAsSpectator);
        return post("/api/joinRoomPage", body)
            .then(function (res) {
            setStatus(res.status);
            setRoomId(res.roomId);
            setLevelName(res.levelName);
            setQuestionAnswers(res.questions === undefined
                ? []
                : res.questions.find(function (q) { return q === null; })
                    ? []
                    : res.questions);
            setSkipsAllowed(res.skipsAllowed);
            setUsers(res.users);
            setSpectatingUsers(res.spectatingUsers);
            setSpectating(res.spectating);
            if (res.status == "inProgress") {
                if (res.startTime && res.timeLimit) {
                    setStartTime(new Date(new Date(res.startTime).getTime() + 1000 * res.timeLimit));
                    setNumSeconds(res.timeLimit);
                }
            }
            else if (res.status == "aboutToStart") {
                if (res.startTime) {
                    setStartTime(res.startTime);
                    setNumSeconds(3);
                }
            }
            if (res.question)
                setQuestion(res.question);
            if (res.scores)
                setScores(res.scores);
        })
            .catch(function (error) {
            window.location.href = "/";
        });
    };
    useEffect(function () {
        joinRoomPage(true);
    }, [userId]);
    useEffect(function () {
        if (!userId)
            return;
        var callback = function (data) {
            setStatus("aboutToStart");
            setStartTime(data.startTime);
            setScores(data.scores);
        };
        socket.on("aboutToStart", callback);
        return function () {
            socket.off("aboutToStart", callback);
        };
    }, [userId]);
    useEffect(function () {
        if (!userId)
            return;
        var callback = function (data) {
            setQuestion(data.question);
            setStartTime(data.endTime);
            setNumSeconds(data.numSeconds);
            setStatus("inProgress");
            setRatingUpdate({
                oldRatings: new Map(),
                newRatings: new Map(),
                empty: true,
            });
        };
        socket.on("inProgress", callback);
        return function () {
            socket.off("inProgress", callback);
        };
    }, [userId]);
    useEffect(function () {
        if (!userId)
            return;
        var callback = function (data) {
            if (data.spectating) {
                setSpectatingUsers(function (users) {
                    return users.filter(function (user) { return user._id + "" !== data.user._id + ""; }).concat([data.user]);
                });
                setUsers(function (users) { return users.filter(function (user) { return user._id + "" !== data.user._id + ""; }); });
            }
            else {
                setUsers(function (users) {
                    return users.filter(function (user) { return user._id + "" !== data.user._id + ""; }).concat([data.user]);
                });
                setSpectatingUsers(function (users) { return users.filter(function (user) { return user._id + "" !== data.user._id + ""; }); });
            }
        };
        socket.on("joinRoomPage", callback);
        return function () {
            socket.off("joinRoomPage", callback);
        };
    }, [userId]);
    useEffect(function () {
        if (!userId)
            return;
        var callback = function (data) {
            setSpectatingUsers(function (users) { return users.filter(function (user) { return user._id + "" !== data.userId; }); });
            setUsers(function (users) { return users.filter(function (user) { return user._id + "" !== data.userId; }); });
        };
        socket.on("leaveRoomPage", callback);
        return function () {
            socket.off("leaveRoomPage", callback);
        };
    }, [userId]);
    useEffect(function () {
        if (!userId)
            return;
        var callback = function (data) {
            setStatus("complete");
            setScores(data.scores);
            setQuestionAnswers(data.questions);
            setRematchDisabled(true);
            setTimeout(function () {
                setRematchDisabled(false);
            }, 5000);
            if (!spectatingModeOn) {
                setSpectating(false);
            }
        };
        socket.on("complete", callback);
        return function () {
            socket.off("complete", callback);
        };
    }, [userId, spectatingModeOn]);
    useEffect(function () {
        if (!userId)
            return;
        var callback = function (data) {
            setScores(data.scores);
        };
        socket.on("guess", callback);
        return function () {
            socket.off("guess", callback);
        };
    }, [userId]);
    useEffect(function () {
        if (!userId)
            return;
        var callback = function (data) {
            setMessages(function (messages) { return messages.concat(data.message); });
        };
        socket.on("message", callback);
        return function () {
            socket.off("message", callback);
        };
    }, [userId]);
    useEffect(function () {
        if (!userId)
            return;
        var callback = function (data) {
            console.log("rating update", data);
            setRatingUpdate({
                empty: data.empty,
                oldRatings: new Map(Object.entries(data.oldRatingMap)),
                newRatings: new Map(Object.entries(data.newRatingMap)),
            });
        };
        socket.on("ratingUpdate", callback);
        return function () {
            socket.off("ratingUpdate", callback);
        };
    }, [userId]);
    useEffect(function () {
        if (!userId)
            return;
        var callback = function (data) {
            setUsers(function (users) {
                var newUsers = users.concat([]);
                var index = newUsers.findIndex(function (user) { return user._id + "" === data._id + ""; });
                if (index > -1) {
                    newUsers[index].name = data.name;
                }
                return newUsers;
            });
            setSpectatingUsers(function (users) {
                var newUsers = users.concat([]);
                var index = newUsers.findIndex(function (user) { return user._id + "" === data._id + ""; });
                if (index > -1) {
                    newUsers[index].name = data.name;
                }
                return newUsers;
            });
        };
        socket.on("updateUserName", callback);
        return function () {
            socket.off("updateUserName", callback);
        };
    }, [userId]);
    var guess = function (userGuess) {
        if (roomId === "")
            return;
        var body = { roomId: roomId, answer: userGuess };
        post("/api/guess", body).then(function (res) {
            if (res.goToNextQuestion) {
                setQuestion(res.question);
            }
        });
    };
    var skip = useCallback(function () {
        if (roomId === "")
            return;
        if (new Date().getTime() - lastSkip.getTime() < 1000 * 2.5)
            return;
        var body = { roomId: roomId, answer: "", skip: true };
        setLastSkip(new Date());
        post("/api/guess", body).then(function (res) {
            if (res.goToNextQuestion) {
                setQuestion(res.question);
            }
        });
    }, [lastSkip, roomId]);
    return (_jsxs(Grid, __assign({ container: true, width: "100%", direction: "row" }, { children: [props.userName && (_jsx(SetName, { currentName: props.userName, setCurrentName: props.setUserName, onClose: function () {
                    setSetNameOpen(false);
                }, open: setNameOpen, joinRoomPage: joinRoomPage })), _jsx(Box, __assign({ width: "calc(100% - 250px)" }, { children: _jsx(RoomMain, { roomId: roomId, status: status, scores: scores, users: users, startTime: startTime, numSeconds: numSeconds, rematchDisabled: rematchDisabled, question: question, questionAnswers: questionAnswers, guess: guess, skip: skipsAllowed ? skip : undefined, userId: userId, spectating: spectating, waitingPlayers: status === "waiting"
                        ? []
                        : users.filter(function (user) {
                            return scores.find(function (entry) { return entry.userId + "" === user._id + ""; }) ? false : true;
                        }), spectators: spectatingUsers, ratingUpdate: ratingUpdate }) })), _jsx(Box, __assign({ width: "250px", padding: "20px", borderLeft: "1px solid #E0E0E0", height: "100vh" }, { children: _jsx(RoomSideBar, { levelName: levelName, roomId: roomId, messages: messages, loggedInGoogle: props.loggedInGoogle, handleLogin: props.handleLogin, spectating: spectatingModeOn }) }))] })));
};
export default RoomPage;
