var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import DOMpurify from "dompurify";
var AnnouncementBanner = function (_a) {
    var announcement = _a.announcement;
    // Hardcoded userColor to blue
    var userColor = "blue";
    // Define CSS styles for the announcement banner
    var bannerStyles = {
        backgroundColor: "#eff6ff",
        borderLeft: "4px solid #3b82f6",
        color: "#1e40af",
        padding: "16px",
        marginBottom: "16px",
        cursor: (announcement === null || announcement === void 0 ? void 0 : announcement.link) ? "pointer" : "default", // Change cursor if there's a link
    };
    // Define CSS styles for hover state if there's a link
    var hoverStyles = (announcement === null || announcement === void 0 ? void 0 : announcement.link)
        ? {
            backgroundColor: "#bfdbfe", // Slightly darker blue on hover
        }
        : {};
    return (announcement === null || announcement === void 0 ? void 0 : announcement.text) ? (_jsx("div", __assign({ style: __assign(__assign({}, bannerStyles), hoverStyles), role: "alert", onClick: function () {
            if (announcement === null || announcement === void 0 ? void 0 : announcement.link)
                window.open(announcement === null || announcement === void 0 ? void 0 : announcement.link, "_blank");
        } }, { children: _jsx(ReactMarkdown, __assign({ rehypePlugins: [rehypeRaw] }, { children: DOMpurify.sanitize((announcement === null || announcement === void 0 ? void 0 : announcement.text) || "", { ADD_ATTR: ["target"] }) })) }))) : (_jsx(_Fragment, {}));
};
export default AnnouncementBanner;
