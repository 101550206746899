var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LobbyLeaderboard from "./LobbyLeaderboard";
import UserInfo from "./UserInfo";
var LobbySideBar = function (props) {
    var _a;
    var levelId = props.levelId, levels = props.levels, setLevelId = props.setLevelId, leaderboard = props.leaderboard;
    return (_jsxs(Grid, __assign({ container: true, direction: "column" }, { children: [_jsx(Typography, __assign({ variant: "h4", align: "center", color: "#306AFF", fontWeight: "bold", marginBottom: "10px" }, { children: "QuickMaths" })), _jsx(Box, { children: _jsxs(FormControl, __assign({ fullWidth: true }, { children: [_jsx(InputLabel, __assign({ id: "demo-simple-select-label" }, { children: "Level" })), _jsx(Select, __assign({ labelId: "demo-simple-select-label", id: "demo-simple-select", value: levelId, label: "Level", onChange: function (event) {
                                setLevelId(event.target.value);
                            } }, { children: levels.map(function (level) { return (_jsx(MenuItem, __assign({ value: level._id }, { children: level.title }), level._id)); }) }))] })) }), props.userInfo && (_jsx(UserInfo, { name: props.userInfo.name, rating: props.userInfo.rating, highScore: props.userInfo.highScore })), _jsx(LobbyLeaderboard, { leaderboard: leaderboard, userId: (_a = props.userInfo) === null || _a === void 0 ? void 0 : _a._id })] })));
};
export default LobbySideBar;
