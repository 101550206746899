var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from "react";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import { styled } from "@mui/material/styles";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { ListItemText, ListItem, List, Grid, Box } from "@mui/material";
var BorderLinearProgress = styled(LinearProgress)(function (_a) {
    var _b;
    var theme = _a.theme, special = _a.special, isonline = _a.isonline;
    return (_b = {
            height: 10,
            borderRadius: 5
        },
        _b["&.".concat(linearProgressClasses.colorPrimary)] = {
            backgroundColor: theme.palette.grey[200],
        },
        _b["& .".concat(linearProgressClasses.bar)] = {
            borderRadius: 5,
            backgroundColor: special ? "#FF32DE" : isonline ? "#1a90ff" : "#424242",
        },
        _b);
});
var getListItem = function (isCurrentUser, name, score, max, isOnline, hideScore, ratingUpdate) {
    var percent = (100.0 * score) / Math.max(10, max);
    return (_jsx(ListItem, __assign({ sx: [
            {
                "&:hover": {
                    backgroundColor: "#F5F5F5",
                },
            },
        ] }, { children: _jsxs(Grid, __assign({ container: true, direction: "row", justifyContent: "center", alignItems: "center" }, { children: [_jsx(Box, __assign({ width: "150px" }, { children: _jsx(ListItemText, { primary: "".concat(name), primaryTypographyProps: {
                            color: isCurrentUser ? "#FF32DE" : isOnline ? "#306AFF" : "#000000",
                            fontWeight: isCurrentUser ? "bold" : undefined,
                        }, secondary: hideScore
                            ? ratingUpdate
                            : ratingUpdate
                                ? "".concat(+score.toFixed(1), " (").concat(ratingUpdate, ")")
                                : "".concat(+score.toFixed(1)) }) })), _jsxs(Box, __assign({ width: "calc(100% - 150px)" }, { children: [_jsx(Box, __assign({ marginLeft: "calc(".concat(percent, "% - 0px)"), style: { transition: "all 0.75s ease" } }, { children: _jsx(DirectionsBikeIcon, { fontSize: "large", style: {
                                    color: isCurrentUser ? "#FF32DE" : isOnline ? "#1a90ff" : "#424242",
                                } }) })), _jsx(BorderLinearProgress, { special: isCurrentUser ? 1 : 0, variant: "determinate", value: percent, isonline: isOnline ? 1 : 0 })] }))] })) }), name));
};
var Scores = function (props) {
    var _a;
    var _b = React.useState(1), max = _b[0], setMax = _b[1];
    useEffect(function () {
        if (props.scores) {
            var largest = 1;
            for (var i = 0; i < props.scores.length; i++) {
                if (props.scores[i].score > largest) {
                    largest = props.scores[i].score;
                }
            }
            setMax(largest);
        }
    }, [props.scores]);
    var listItems = [];
    if (props.scores) {
        listItems = props.scores
            .sort(function (a, b) { return b.score - a.score; })
            .map(function (entry) {
            var _a;
            return getListItem(entry.userId === props.userId, entry.name, entry.score, max, ((_a = props.users) === null || _a === void 0 ? void 0 : _a.find(function (u) { return u._id + "" === entry.userId; })) ? true : false, false, props.ratingUpdate && !props.ratingUpdate.empty
                ? "".concat(Math.round(props.ratingUpdate.oldRatings.get(entry.userId + "") || 0), " -> ").concat(Math.round(props.ratingUpdate.newRatings.get(entry.userId + "") || 0))
                : undefined);
        });
    }
    else {
        listItems =
            ((_a = props.users) === null || _a === void 0 ? void 0 : _a.map(function (entry) {
                return getListItem(entry._id === props.userId, entry.name, 0, 1, true, true, "" + Math.round(entry.rating));
            })) || [];
    }
    return (_jsx(Grid, __assign({ container: true, direction: "column" }, { children: _jsx(List, { children: listItems }) })));
};
export default Scores;
