var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import Alert from "@mui/material/Alert";
var ForceDisconnected = function () {
    return (_jsx("div", { children: _jsx(Dialog, __assign({ fullScreen: true, open: true, onClose: function () { } }, { children: _jsx(DialogContent, __assign({ style: { backgroundColor: "#6c57f5", color: "white" } }, { children: _jsx(Box, __assign({ marginTop: "20px" }, { children: _jsx(Alert, __assign({ variant: "standard", severity: "warning" }, { children: "You have been disconnected - perhaps for opening another Tab of Quickmaths. Refresh to return to QuickMaths!" })) })) })) })) }));
};
export default ForceDisconnected;
