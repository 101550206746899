var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { post } from "../../utilities";
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, TextField, } from "@mui/material";
var SetName = function (props) {
    var _a = useState(props.currentName), newName = _a[0], setNewName = _a[1];
    // edit name modal
    return (_jsxs(Dialog, __assign({ open: props.open, onClose: props.onClose }, { children: [_jsx(DialogTitle, { children: "Change Name" }), _jsx(DialogContent, { children: _jsx(TextField, { margin: "dense", label: "Name", type: "text", fullWidth: true, value: newName, onChange: function (event) {
                        setNewName(event.target.value.substring(0, 15));
                    }, autoFocus: true, onKeyPress: function (event) {
                        if (event.key === "Enter") {
                            if (newName.length < 1)
                                return;
                            post("/api/changeName", { name: newName }).then(function () {
                                props.setCurrentName(newName);
                                props.onClose();
                            });
                        }
                    } }) }), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ onClick: function () {
                            post("/api/changeName", { name: newName }).then(function () {
                                props.setCurrentName(newName);
                                props.onClose();
                            });
                        }, disabled: (newName.length < 1 || newName.length > 15) && newName !== props.currentName, color: "secondary" }, { children: "Spectate" })), _jsx(Button, __assign({ onClick: function () {
                            post("/api/changeName", { name: newName }).then(function () {
                                props.setCurrentName(newName);
                                props.joinRoomPage(false).then(function () {
                                    props.onClose();
                                });
                            });
                        }, disabled: (newName.length < 1 || newName.length > 15) && newName !== props.currentName, color: "primary" }, { children: "Join the Race!" }))] })] })));
};
export default SetName;
