var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import AirlineSeatReclineExtraIcon from "@mui/icons-material/AirlineSeatReclineExtra";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
var Watchers = function (props) {
    var waitingPlayerElements = props.waitingPlayers.map(function (watcher) {
        return (_jsxs(Grid, __assign({ padding: "10px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }, { children: [_jsx(DirectionsBikeIcon, { fontSize: "medium" }), _jsx(Typography, __assign({ variant: "body2", align: "center", sx: { fontWeight: "bold" } }, { children: watcher.name }))] }), watcher._id));
    });
    var spectatorElements = props.spectators.map(function (watcher) {
        return (_jsxs(Grid, __assign({ padding: "10px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }, { children: [_jsx(AirlineSeatReclineExtraIcon, { fontSize: "medium" }), _jsx(Typography, __assign({ variant: "body2", align: "center", sx: { fontWeight: "bold" } }, { children: watcher.name }))] }), watcher._id));
    });
    return (_jsx(Grid, __assign({ container: true, direction: "row" }, { children: waitingPlayerElements.concat(spectatorElements) })));
};
export default Watchers;
