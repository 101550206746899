/**
 * Utility functions to make API requests.
 * By importing this file, you can use the provided get and post functions.
 * You shouldn't need to modify this file, but if you want to learn more
 * about how these functions work, google search "Fetch API"
 *
 * These functions return promises, which means you should use ".then" on them.
 * e.g. get('/api/foo', { bar: 0 }).then(res => console.log(res))
 */
var formatParams = function (params) {
    return Object.keys(params)
        .map(function (key) { return "".concat(key, "=").concat(encodeURIComponent(params[key])); })
        .join("&");
};
var convertToJSON = function (res) {
    if (!res.ok) {
        throw "API request failed with response status ".concat(res.status, " and text: ").concat(res.statusText);
    }
    return res
        .clone() // clone so that the original is still readable for debugging
        .json() // start converting to JSON object
        .catch(function (error) {
        // throw an error containing the text that couldn't be converted to JSON
        return res.text().then(function (text) {
            throw "API request's result could not be converted to a JSON object: \n".concat(text);
        });
    });
};
// Helper code to make a get request. Default parameter of empty JSON Object for params.
// Returns a Promise to a JSON Object.
export var get = function (endpoint, params) {
    if (params === void 0) { params = {}; }
    var fullPath = endpoint + "?" + formatParams(params);
    return fetch(fullPath)
        .then(convertToJSON)
        .catch(function (error) {
        // give a useful error message
        throw "GET request to ".concat(fullPath, " failed with error:\n").concat(error);
    });
};
// Helper code to make a post request. Default parameter of empty JSON Object for params.
// Returns a Promise to a JSON Object.
export var post = function (endpoint, params) {
    if (params === void 0) { params = {}; }
    return fetch(endpoint, {
        method: "post",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(params),
    })
        .then(convertToJSON) // convert result to JSON object
        .catch(function (error) {
        // give a useful error message
        throw "POST request to ".concat(endpoint, " failed with error:\n").concat(error);
    });
};
