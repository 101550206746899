var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import ReactDOM from "react-dom";
import App from "./components/App";
import { GoogleOAuthProvider } from "@react-oauth/google";
import posthog from "posthog-js";
posthog.init("phc_5hmeNJNA5m90QOrulD77gjNo7MVIc9vLesFCP7krC6u", {
    api_host: "https://app.posthog.com",
});
var GOOGLE_CLIENT_ID = "180080882825-to464u7earqlme8o2muc5e0bqll8llih.apps.googleusercontent.com";
// renders React Component "Root" into the DOM element with ID "root"
ReactDOM.render(_jsx(GoogleOAuthProvider, __assign({ clientId: GOOGLE_CLIENT_ID }, { children: _jsx(App, {}) })), document.getElementById("root"));
// allows for live updating
module.hot.accept();
