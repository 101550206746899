var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { post } from "../../utilities";
import RoomList from "./RoomList";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
var LobbyMain = function (props) {
    var newRoom = function (isPrivate) {
        if (props.level) {
            var body = { private: isPrivate, levelId: props.level._id };
            post("/api/createRoom", body).then(function (data) {
                window.location.href = "/" + data.room.name;
            });
        }
    };
    return (_jsxs(Grid, __assign({ container: true, direction: "column" }, { children: [props.level && (_jsxs(Grid, __assign({ container: true, direction: "row", width: "100%", marginBottom: "10px" }, { children: [_jsx(Box, __assign({ width: "50%" }, { children: _jsx(Button, __assign({ fullWidth: true, color: "primary", onClick: function () {
                                newRoom(false);
                            } }, { children: "New ".concat(props.level.title, " Game") })) })), _jsx(Box, __assign({ width: "50%" }, { children: _jsx(Button, __assign({ fullWidth: true, color: "inherit", onClick: function () {
                                newRoom(true);
                            } }, { children: "Private Game" })) }))] }))), _jsx(RoomList, { rooms: props.rooms.sort(function (a, b) {
                    return new Date(b.lastActive).getTime() - new Date(a.lastActive).getTime();
                }) })] })));
};
export default LobbyMain;
