var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GoogleLogin } from "@react-oauth/google";
import { Box, Grid, Typography, Button } from "@mui/material";
import Chat from "./Chat";
var RoomSideBar = function (props) {
    return (_jsxs(Grid, __assign({ container: true, direction: "column", justifyContent: "center" }, { children: [_jsx(Typography, __assign({ variant: "h4", align: "center", color: props.spectating ? "#444444" : "#306AFF", sx: { fontWeight: "bold", marginRight: "6px" } }, { children: "QuickMaths" })), _jsx(Typography, __assign({ variant: "h5", align: "center", color: "#000000", sx: { fontWeight: "bold", marginRight: "6px" } }, { children: props.levelName })), _jsx(Chat, { roomId: props.roomId, messages: props.messages, spectating: props.spectating }), _jsx(Button, __assign({ fullWidth: true, variant: "text", style: { marginTop: "10px" }, onClick: function () {
                    window.location.href = "/";
                } }, { children: "Back to Lobby" })), props.loggedInGoogle ? ("") : (_jsx(Box, __assign({ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }, { children: _jsx(GoogleLogin, { logo_alignment: "center", onSuccess: props.handleLogin, onError: function () { return console.log("Login Failed"); } }) })))] })));
};
export default RoomSideBar;
