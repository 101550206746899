var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { post } from "../../utilities";
import AceEditor from "react-ace";
import { Grid, Typography, Tab, Accordion, AccordionSummary, AccordionDetails, TextField, Button, Snackbar, Alert, Select, MenuItem, List, ListItem, ListItemSecondaryAction, ListItemText, Checkbox, FormControlLabel, } from "@mui/material";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import QuestionBox from "../modules/QuestionBox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
var AdminPage = function (props) {
    var _a = useState([]), levels = _a[0], setLevels = _a[1];
    var _b = useState([]), questionTypes = _b[0], setQuestionTypes = _b[1];
    var _c = useState([]), bots = _c[0], setBots = _c[1];
    var _d = useState("0"), tab = _d[0], setTab = _d[1];
    var _e = useState(false), snackbarOpen = _e[0], setSnackbarOpen = _e[1];
    var _f = useState(""), snackbarMessage = _f[0], setSnackbarMessage = _f[1];
    var handleClose = function () {
        setSnackbarOpen(false);
    };
    var successFunction = function (msg) {
        setSnackbarMessage(msg);
        setSnackbarOpen(true);
    };
    useEffect(function () {
        if (!props.userId)
            return;
        post("/api/joinAdminPage").then(function (res) {
            setLevels(res.levels);
            setQuestionTypes(res.questionTypes);
            setBots(res.bots);
        });
    }, [props.userId, tab]);
    return (_jsxs(Grid, __assign({ container: true, width: "100%", direction: "column" }, { children: [_jsx(Typography, __assign({ variant: "h4", align: "center", color: "#306AFF", fontWeight: "bold", marginBottom: "10px", marginTop: "20px" }, { children: "QuickMaths" })), _jsx(Typography, __assign({ variant: "h5", align: "center", fontWeight: "bold", marginBottom: "10px" }, { children: "Admin Panel" })), _jsxs(TabContext, __assign({ value: tab }, { children: [_jsxs(TabList, __assign({ value: tab, onChange: function (event, value) { return setTab(value); } }, { children: [_jsx(Tab, { label: "Levels", value: "0" }), _jsx(Tab, { label: "Question Types", value: "1" }), _jsx(Tab, { label: "Bots", value: "2" }), _jsx(Tab, { label: "Leaderboard", value: "3" })] })), _jsxs(TabPanel, __assign({ value: "0" }, { children: [_jsx(Button, __assign({ onClick: function () {
                                    post("/api/newLevel").then(function (data) {
                                        setLevels(function (levels) { return __spreadArray(__spreadArray([], levels, true), [data.level], false); });
                                    });
                                }, variant: "contained", style: { marginBottom: "20px" } }, { children: "New Level" })), levels.map(function (level, index) { return (_jsx(AdminLevel, { level: level, successFunction: successFunction, questionTypes: questionTypes }, index)); })] })), _jsxs(TabPanel, __assign({ value: "1" }, { children: [_jsx(Button, __assign({ onClick: function () {
                                    post("/api/newQuestionType").then(function (data) {
                                        setQuestionTypes(function (questionTypes) { return __spreadArray(__spreadArray([], questionTypes, true), [data.questionType], false); });
                                    });
                                }, style: { marginBottom: "20px" }, variant: "contained" }, { children: "New Question Type" })), questionTypes.map(function (questionType, index) { return (_jsx(AdminQuestionType, { questionType: questionType, successFunction: successFunction }, index)); })] })), _jsxs(TabPanel, __assign({ value: "2" }, { children: [_jsx(NewBot, { setBots: setBots }), _jsx(List, __assign({ dense: true, disablePadding: true }, { children: bots &&
                                    bots.map(function (bot, i) { return (_jsxs(ListItem, __assign({ disableGutters: true, style: { paddingLeft: "10px" } }, { children: [_jsx(ListItemText, { primary: bot.name, primaryTypographyProps: {
                                                    variant: "body2",
                                                } }), _jsx(ListItemSecondaryAction, __assign({ style: { paddingRight: "10px" } }, { children: _jsx(Typography, __assign({ component: "div", variant: "body1", color: "primary" }, { children: bot.difficulty })) }))] }), i)); }) }))] })), _jsx(TabPanel, __assign({ value: "3" }, { children: _jsx(Button, __assign({ onClick: function () {
                                post("/api/reinitializeLeaderboard").then(function () {
                                    if (successFunction)
                                        successFunction("Successfully reinitialized leaderboard");
                                });
                            }, variant: "contained", style: { marginBottom: "20px" } }, { children: "Re-initialize Leaderboard" })) }))] })), _jsx(Snackbar, __assign({ open: snackbarOpen, autoHideDuration: 6000, onClose: handleClose }, { children: _jsx(Alert, __assign({ onClose: handleClose, severity: "success", sx: { width: "100%" } }, { children: snackbarMessage })) }))] })));
};
var AdminQuestionType = function (props) {
    var questionType = props.questionType, successFunction = props.successFunction;
    var _a = useState(questionType.pythonScript), pythonScript = _a[0], setPythonScript = _a[1];
    var _b = useState(questionType.title), title = _b[0], setTitle = _b[1];
    var _c = useState(undefined), trialQuestion = _c[0], setTrialQuestion = _c[1];
    var _d = useState(questionType.hasCorrectnessChecker), hasCorrectnessChecker = _d[0], setHasCorrectnessChecker = _d[1];
    var _e = useState(false), expanded = _e[0], setExpanded = _e[1];
    return (_jsxs(Accordion, __assign({ expanded: expanded, onChange: function (event, expanded) {
            setExpanded(expanded);
        } }, { children: [_jsx(AccordionSummary, __assign({ expandIcon: _jsx(ExpandMoreIcon, {}), id: questionType._id + "summary" }, { children: expanded ? _jsx(_Fragment, {}) : _jsx(Typography, { children: title }) })), _jsxs(AccordionDetails, { children: [_jsx(TextField, { label: "title", value: title, onChange: function (event) { return setTitle(event.target.value); }, style: { marginBottom: "10px" } }), _jsx(AceEditor, { mode: "python", theme: "github", value: pythonScript, onChange: setPythonScript }), _jsxs(Grid, __assign({ container: true, direction: "row", marginTop: "10px" }, { children: [_jsx(FormControlLabel, { label: "Correctness Checker", labelPlacement: "top", control: _jsx(Checkbox, { checked: !!hasCorrectnessChecker, onChange: function (event) {
                                        setHasCorrectnessChecker(event.target.checked);
                                    } }) }), _jsx(Button, __assign({ onClick: function () {
                                    post("/api/tryCode", {
                                        questionTypeId: questionType._id,
                                        pythonScript: pythonScript,
                                    }).then(function (data) {
                                        var question = data.question;
                                        setTrialQuestion(question);
                                    });
                                } }, { children: "Try Code" })), _jsx(Button, __assign({ onClick: function () {
                                    var savedTitle = title;
                                    post("/api/saveQuestionType", {
                                        questionType: {
                                            _id: questionType._id,
                                            pythonScript: pythonScript,
                                            title: title,
                                            hasCorrectnessChecker: hasCorrectnessChecker,
                                        },
                                    }).then(function () {
                                        if (successFunction)
                                            successFunction("Successfully saved ".concat(savedTitle));
                                    });
                                } }, { children: "Save" }))] })), _jsx(QuestionBox, { question: trialQuestion, fixed: true })] })] })));
};
var AdminLevel = function (props) {
    var level = props.level, successFunction = props.successFunction, questionTypes = props.questionTypes;
    var _a = useState(level.distribution), distribution = _a[0], setDistribution = _a[1];
    var _b = useState(level.distribution.map(function (entry) { return entry.questionTypeId; })), distributionIds = _b[0], setDistributionIds = _b[1];
    var _c = useState(level.title), title = _c[0], setTitle = _c[1];
    var _d = useState(false), expanded = _d[0], setExpanded = _d[1];
    var _e = useState(0), changeAmount = _e[0], setChangeAmount = _e[1];
    var _f = useState(level.difficultyParam1), botParam1 = _f[0], setBotParam1 = _f[1];
    var _g = useState(level.difficultyParam2), botParam2 = _g[0], setBotParam2 = _g[1];
    var _h = useState(level.numSeconds), numSeconds = _h[0], setNumSeconds = _h[1];
    var _j = useState(level.penaltyIncorrect), penaltyIncorrect = _j[0], setPenaltyIncorrect = _j[1];
    var _k = useState(level.skipsAllowed), skipsAllowed = _k[0], setSkipsAllowed = _k[1];
    var _l = useState(level.penaltySkip), penaltySkip = _l[0], setPenaltySkip = _l[1];
    var _m = useState(level.featured || false), featured = _m[0], setFeatured = _m[1];
    return (_jsxs(Accordion, __assign({ expanded: expanded, onChange: function (event, expanded) {
            setExpanded(expanded);
        } }, { children: [_jsx(AccordionSummary, __assign({ expandIcon: _jsx(ExpandMoreIcon, {}), id: level._id + "summary" }, { children: expanded ? _jsx(_Fragment, {}) : _jsx(Typography, { children: title }) })), _jsxs(AccordionDetails, { children: [_jsx(TextField, { label: "title", value: title, onChange: function (event) { return setTitle(event.target.value); } }), _jsxs(Grid, __assign({ container: true, direction: "column", marginTop: "10px" }, { children: [_jsx(Select, __assign({ multiple: true, 
                                //@ts-ignore
                                value: distributionIds, variant: "filled", onChange: function (event) {
                                    var value = event.target.value;
                                    var newDistributionIds = typeof value === "string" ? value.split(",") : value;
                                    setDistributionIds(typeof value === "string" ? value.split(",") : value);
                                    setDistribution(function (curDistribution) {
                                        return newDistributionIds.map(function (id) {
                                            var existingEntry = curDistribution.find(function (entry) { return entry.questionTypeId === id; });
                                            if (existingEntry)
                                                return existingEntry;
                                            return { questionTypeId: id, percentage: 0 };
                                        });
                                    });
                                } }, { children: questionTypes.map(function (entry, index) { return (_jsx(MenuItem, __assign({ value: entry._id + "" }, { children: entry.title }), index)); }) })), _jsx(Grid, __assign({ container: true, marginTop: "15px" }, { children: distribution.map(function (entry, i) {
                                    var _a;
                                    var name = ((_a = questionTypes.find(function (questionType) { return questionType._id + "" === entry.questionTypeId; })) === null || _a === void 0 ? void 0 : _a.title) || "No title";
                                    return (_jsx(TextField, { label: name, type: "number", style: { margin: "5px 5px 5px 5px" }, value: entry.percentage, onChange: function (event) {
                                            var value = parseInt(event.target.value);
                                            setDistribution(function (curDistribution) {
                                                return curDistribution.map(function (curEntry) {
                                                    if (curEntry.questionTypeId === entry.questionTypeId) {
                                                        return {
                                                            questionTypeId: curEntry.questionTypeId,
                                                            percentage: value,
                                                        };
                                                    }
                                                    else {
                                                        return curEntry;
                                                    }
                                                });
                                            });
                                        } }, i));
                                }) })), _jsxs(Grid, __assign({ container: true, direction: "row" }, { children: [_jsx(TextField, { label: "Bot time parameter 1", type: "number", inputProps: { step: "0.5" }, style: { margin: "5px 5px 5px 5px" }, value: botParam1, onChange: function (event) {
                                            var value = parseFloat(event.target.value);
                                            setBotParam1(value);
                                        } }), _jsx(TextField, { label: "Bot time parameter 2", type: "number", inputProps: { step: "0.5" }, style: { margin: "5px 5px 5px 5px" }, value: botParam2, onChange: function (event) {
                                            var value = parseFloat(event.target.value);
                                            setBotParam2(value);
                                        } })] })), _jsxs(Grid, __assign({ container: true, direction: "row" }, { children: [_jsx(TextField, { label: "Total number of seconds", type: "number", inputProps: { step: "1" }, style: { margin: "5px 5px 5px 5px" }, value: numSeconds, onChange: function (event) {
                                            var value = parseInt(event.target.value);
                                            setNumSeconds(value);
                                        } }), _jsx(TextField, { label: "Penalty for incorrect answer", type: "number", inputProps: { step: "0.1" }, style: { margin: "5px 5px 5px 5px" }, value: penaltyIncorrect, onChange: function (event) {
                                            var value = parseFloat(event.target.value);
                                            setPenaltyIncorrect(value);
                                        } })] })), _jsxs(Grid, __assign({ container: true, direction: "row" }, { children: [_jsx(FormControlLabel, { label: "Skips allowed", labelPlacement: "top", control: _jsx(Checkbox, { checked: !!skipsAllowed, onChange: function (event) {
                                                setSkipsAllowed(event.target.checked);
                                            } }) }), skipsAllowed ? (_jsx(TextField, { label: "Penalty for skip", type: "number", inputProps: { step: "0.1" }, style: { margin: "5px 5px 5px 5px" }, value: penaltySkip, onChange: function (event) {
                                            var value = parseFloat(event.target.value);
                                            setPenaltySkip(value);
                                        } })) : (_jsx("div", {})), _jsx(FormControlLabel, { label: "Featured", labelPlacement: "top", control: _jsx(Checkbox, { checked: !!featured, onChange: function (event) {
                                                setFeatured(event.target.checked);
                                            } }) })] })), _jsx(Grid, __assign({ container: true, direction: "row" }, { children: _jsx(Button, __assign({ onClick: function () {
                                        var savedTitle = title;
                                        post("/api/saveLevel", {
                                            newLevel: {
                                                _id: level._id,
                                                title: title,
                                                distribution: distribution,
                                                difficultyParam1: botParam1,
                                                difficultyParam2: botParam2,
                                                numSeconds: numSeconds,
                                                penaltyIncorrect: penaltyIncorrect,
                                                penaltySkip: penaltySkip,
                                                skipsAllowed: skipsAllowed,
                                                featured: featured,
                                            },
                                        }).then(function () {
                                            if (successFunction)
                                                successFunction("Successfully saved ".concat(savedTitle));
                                        });
                                    }, disabled: distribution.map(function (d) { return d.percentage; }).reduce(function (a, b) { return a + b; }, 0) !== 100 }, { children: "Save" })) }))] }))] })] })));
};
var NewBot = function (_a) {
    var setBots = _a.setBots;
    var _b = useState(""), name = _b[0], setName = _b[1];
    var _c = useState(5), difficulty = _c[0], setDifficulty = _c[1];
    var _d = useState(1200), startingRating = _d[0], setStartingRating = _d[1];
    return (_jsxs(Grid, __assign({ container: true, direction: "row", marginBottom: "20px" }, { children: [_jsx(TextField, { value: name, label: "Name", onChange: function (event) {
                    setName(event.target.value);
                }, style: { marginRight: "20px" } }), _jsx(TextField, { value: difficulty, label: "Difficulty", type: "number", onChange: function (event) {
                    setDifficulty(parseInt(event.target.value));
                }, style: { marginRight: "20px" } }), _jsx(TextField, { value: startingRating, label: "Starting Rating", type: "number", onChange: function (event) {
                    setStartingRating(parseInt(event.target.value));
                }, style: { marginRight: "20px" } }), _jsx(Button, __assign({ onClick: function () {
                    post("/api/createBot", { name: name, difficulty: difficulty, startingRating: startingRating }).then(function (data) {
                        setBots(function (bots) { return __spreadArray(__spreadArray([], bots, true), [data.bot], false); });
                    });
                }, variant: "contained" }, { children: "New Bot" }))] })));
};
export default AdminPage;
