var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { navigate } from "@reach/router";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PersonIcon from "@mui/icons-material/Person";
import PeopleIcon from "@mui/icons-material/People";
import GroupsIcon from "@mui/icons-material/Groups";
import Badge from "@mui/material/Badge";
var formatDate = function (date) {
    var seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);
    if (seconds > -2 && seconds < 2)
        return "Just now";
    if (seconds < 60)
        return seconds + (seconds === 1 ? " sec ago" : " sec ago");
    var minutes = Math.floor(seconds / 60);
    if (minutes < 60)
        return minutes + (minutes === 1 ? " min ago" : " min ago");
    var hours = Math.floor(minutes / 60);
    if (hours < 24)
        return hours + (hours === 1 ? " hour ago" : " hours ago");
    var days = Math.floor(hours / 24);
    return days + (days === 1 ? " day ago" : " days ago");
};
var StyledTableCell = styled(TableCell)(function (_a) {
    var _b;
    var theme = _a.theme;
    return (_b = {},
        _b["&.".concat(tableCellClasses.head)] = {
            backgroundColor: "#306AFF",
            color: theme.palette.common.white,
        },
        _b["&.".concat(tableCellClasses.body)] = {
            fontSize: 14,
        },
        _b);
});
var StyledTableRow = styled(TableRow)(function (_a) {
    var theme = _a.theme;
    return ({
        "&:nth-of-type(odd)": {
            backgroundColor: "#FFFFFF",
        },
        // hide last border
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    });
});
var icon = function (numPeople) {
    if (numPeople == 0) {
        return _jsx(_Fragment, {});
    }
    else if (numPeople == 1) {
        return _jsx(PersonIcon, { htmlColor: "#306AFF" });
    }
    else if (numPeople == 2) {
        return _jsx(PeopleIcon, { htmlColor: "#306AFF" });
    }
    else if (numPeople == 3) {
        return _jsx(GroupsIcon, { htmlColor: "#306AFF" });
    }
    else {
        return _jsx(GroupsIcon, { htmlColor: "#306AFF" });
    }
};
var RoomList = function (props) {
    return (_jsx(TableContainer, __assign({ component: Paper }, { children: _jsx(Table, __assign({ sx: { minWidth: 700 }, "aria-label": "customized table" }, { children: _jsx(TableBody, { children: props.rooms
                    .sort(function (a, b) { return b.players - a.players; })
                    .map(function (room) { return (_jsxs(StyledTableRow, __assign({ hover: true, onClick: function () {
                        navigate("/".concat(room.name));
                    } }, { children: [_jsx(StyledTableCell, __assign({ component: "th", scope: "row" }, { children: room.levelName })), _jsx(StyledTableCell, __assign({ align: "right" }, { children: room.inProgress ? "In Progress" : formatDate(new Date(room.lastActive)) })), _jsx(StyledTableCell, __assign({ align: "right" }, { children: _jsx(Badge, __assign({ badgeContent: room.players, color: "primary" }, { children: icon(room.players) })) }))] }), room.name)); }) }) })) })));
};
export default RoomList;
