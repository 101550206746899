var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Latex from "react-latex";
var QuestionBox = function (props) {
    var _a, _b;
    var _c = useState(""), userAnswer = _c[0], setUserAnswer = _c[1];
    var _d = useState(new Date()), lastGuess = _d[0], setLastGuess = _d[1];
    if (!props.question) {
        return _jsx(Box, {});
    }
    return (_jsxs(Grid, __assign({ container: true, direction: "column", justifyContent: "center" }, { children: [_jsx(Box, __assign({ width: "100%", marginTop: "10px" }, { children: _jsx(Typography, __assign({ variant: "h6", color: "#306AFF" }, { children: "Question ".concat((_a = props.question) === null || _a === void 0 ? void 0 : _a.number) })) })), _jsx(Box, __assign({ marginTop: "10px", marginBottom: "20px" }, { children: _jsx(Typography, __assign({ variant: "h5", component: "span", whiteSpace: "pre-wrap" }, { children: _jsx(Latex, { children: (_b = props.question) === null || _b === void 0 ? void 0 : _b.prompt }) })) })), props.fixed ? (_jsx(TextField, { label: "Answer", value: props.question.answer, disabled: true })) : (_jsxs(Grid, __assign({ container: true, direction: "row" }, { children: [_jsx(TextField, { label: props.spectating ? "You are spectating" : "Answer", value: userAnswer, onChange: function (event) {
                            setUserAnswer(event.target.value);
                        }, autoFocus: true, disabled: props.spectating ? true : false, onKeyPress: function (event) {
                            if (event.key === "Enter") {
                                if (new Date().getTime() - new Date(lastGuess).getTime() >= 100) {
                                    setUserAnswer("");
                                    props.guess && props.guess(userAnswer);
                                }
                            }
                        }, fullWidth: true }), props.skip && !props.spectating && _jsx(Button, __assign({ onClick: props.skip }, { children: "Skip" }))] })))] })));
};
export default QuestionBox;
