var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import { TabPanel, TabContext, TabList } from "@mui/lab";
var LobbyLeaderboard = function (props) {
    var _a = useState("0"), tab = _a[0], setTab = _a[1];
    return (_jsxs(TabContext, __assign({ value: tab }, { children: [_jsxs(TabList, __assign({ value: tab, onChange: function (event, value) { return setTab(value); } }, { children: [_jsx(Tab, { label: "Ratings", value: "0" }), _jsx(Tab, { label: "Scores", value: "1" })] })), _jsx(TabPanel, __assign({ value: "0", style: { paddingLeft: "0px", paddingTop: "10px", paddingRight: "0px" } }, { children: _jsx(Box, __assign({ height: "calc(100vh - 400px)", overflow: "auto", className: "leaderboard" }, { children: _jsx(List, __assign({ dense: true, disablePadding: true }, { children: props.leaderboard &&
                            props.leaderboard.topRatings.map(function (entry, i) { return (_jsxs(ListItem, __assign({ disableGutters: true, selected: entry.userId === props.userId, style: { paddingLeft: "10px" } }, { children: [_jsx(ListItemText, { primary: entry.userName, primaryTypographyProps: {
                                            variant: "body2",
                                            style: entry.userId === props.userId ? { fontWeight: 900 } : undefined,
                                        } }), _jsx(ListItemSecondaryAction, __assign({ style: { paddingRight: "10px" } }, { children: _jsx(Typography, __assign({ component: "div", variant: "body1", color: "primary", style: entry.userId === props.userId ? { fontWeight: 900 } : undefined }, { children: Math.round(entry.rating) })) }))] }), i)); }) })) })) })), _jsx(TabPanel, __assign({ value: "1", style: { paddingLeft: "10px", paddingTop: "10px" } }, { children: _jsx(Box, __assign({ height: "calc(100vh - 400px)", overflow: "auto", className: "leaderboard" }, { children: _jsx(List, __assign({ dense: true, disablePadding: true }, { children: props.leaderboard &&
                            props.leaderboard.topScores.map(function (entry, i) { return (_jsxs(ListItem, __assign({ disableGutters: true, selected: entry.userId === props.userId, style: { paddingLeft: "10px" } }, { children: [_jsx(ListItemText, { primary: entry.userName, primaryTypographyProps: {
                                            variant: "body2",
                                            style: entry.userId === props.userId ? { fontWeight: 900 } : undefined,
                                        } }), _jsx(ListItemSecondaryAction, __assign({ style: { paddingRight: "10px" } }, { children: _jsx(Typography, __assign({ component: "div", variant: "body1", color: "primary", style: entry.userId === props.userId ? { fontWeight: 900 } : undefined }, { children: +entry.score.toFixed(1) })) }))] }), i)); }) })) })) }))] })));
};
export default LobbyLeaderboard;
