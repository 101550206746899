var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { post } from "../../utilities";
import { GoogleLogin } from "@react-oauth/google";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LobbySideBar from "../modules/LobbySideBar";
import LobbyMain from "../modules/LobbyMain";
import { socket } from "../../client-socket";
import AnnouncementBanner from "../modules/Announcement";
var LobbyPage = function (props) {
    var _a = useState([]), levels = _a[0], setLevels = _a[1];
    var _b = useState(""), levelId = _b[0], setLevelId = _b[1];
    var _c = useState({
        topRatings: [],
        topScores: [],
        topScores24Hours: [],
    }), leaderboard = _c[0], setLeaderboard = _c[1];
    var _d = useState([]), rooms = _d[0], setRooms = _d[1];
    var _e = useState(undefined), userInfo = _e[0], setUserInfo = _e[1];
    var _f = useState(undefined), announcement = _f[0], setAnnouncement = _f[1];
    var userId = props.userId;
    useEffect(function () {
        if (!userId)
            return;
        var body = { levelId: levelId === "" ? undefined : levelId };
        post("/api/joinLobbyPage", body).then(function (res) {
            setLevels(res.levels);
            setLevelId(res.levelId);
            setLeaderboard(res.leaderboard);
            setRooms(res.rooms);
            setUserInfo(res.userInfo);
            setAnnouncement(res.announcement);
        });
    }, [userId, levelId]);
    useEffect(function () {
        if (!userId)
            return;
        var callback = function (room) {
            setRooms(function (rooms) { return rooms.concat([room]); });
        };
        socket.on("newRoom", callback);
        return function () {
            socket.off("newRoom", callback);
        };
    }, [userId]);
    useEffect(function () {
        if (!userId)
            return;
        var callback = function (roomName) {
            setRooms(function (rooms) { return rooms.filter(function (entry) { return entry.name !== roomName; }); });
        };
        socket.on("destroyedRoom", callback);
        return function () {
            socket.off("destroyedRoom", callback);
        };
    }, [userId]);
    useEffect(function () {
        if (!userId)
            return;
        var callback = function (room) {
            setRooms(function (rooms) { return rooms.filter(function (entry) { return entry.name !== room.name; }).concat([room]); });
        };
        socket.on("updateRoom", callback);
        return function () {
            socket.off("updateRoom", callback);
        };
    }, [userId]);
    useEffect(function () {
        if (!userId)
            return;
        var callback = function (leaderboardData) {
            if (levelId === leaderboardData.levelId)
                setLeaderboard(leaderboardData.leaderboard);
        };
        socket.on("leaderboard", callback);
        return function () {
            socket.off("leaderboard", callback);
        };
    }, [userId, levelId]);
    return (_jsxs(Grid, __assign({ container: true, width: "100%", direction: "row" }, { children: [_jsxs(Box, __assign({ width: "250px", padding: "20px" }, { children: [_jsx(LobbySideBar, { levels: levels, levelId: levelId, setLevelId: setLevelId, leaderboard: leaderboard, userInfo: userInfo }), props.loggedInGoogle ? ("") : (_jsx(GoogleLogin, { onSuccess: props.handleLogin, onError: function () { return console.log("Login Failed"); } }))] })), _jsxs(Box, __assign({ width: "calc(100% - 250px)", padding: "20px", paddingLeft: "5px" }, { children: [_jsx(AnnouncementBanner, { announcement: announcement }), _jsx(LobbyMain, { rooms: rooms, level: levels.find(function (level) { return level._id === levelId; }) })] }))] })));
};
export default LobbyPage;
