import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { Router } from "@reach/router";
import NotFound from "./pages/NotFound";
import Skeleton from "./pages/Skeleton";
import LobbyPage from "./pages/LobbyPage";
import RoomPage from "./pages/RoomPage";
import AdminPage from "./pages/AdminPage";
import ForceDisconnected from "./pages/ForceDisconnected";
import "../utilities.css";
import "antd/dist/antd.css";
import { socket } from "../client-socket";
import Cookies from "universal-cookie";
var cookies = new Cookies();
import { get, post } from "../utilities";
import CircularProgress from "@mui/material/CircularProgress";
import posthog from "posthog-js";
/**
 * Define the "App" component
 */
var App = function () {
    var _a = useState(undefined), userId = _a[0], setUserId = _a[1];
    var _b = useState(false), isAdmin = _b[0], setIsAdmin = _b[1];
    var _c = useState(""), userName = _c[0], setUserName = _c[1];
    var _d = useState(false), loggedInGoogle = _d[0], setLoggedInGoogle = _d[1];
    var _e = useState(false), socketConnected = _e[0], setSocketConnected = _e[1];
    var _f = useState(false), forceDisconnected = _f[0], setForceDisconnected = _f[1];
    useEffect(function () {
        if (!socketConnected)
            return;
        var token = cookies.get("cookieToken");
        get("/api/whoami").then(function (user) {
            if (user._id) {
                // they are registed in the database, and currently logged in.
                setUserId(user._id);
                setUserName(user.name);
                setIsAdmin(user.isAdmin);
                setLoggedInGoogle(user.googleid ? true : false);
            }
            else {
                post("/api/login", { cookieToken: token || "no token" }).then(function (user) {
                    post("/api/initsocket", { socketid: socket.id }).then(function () {
                        setUserId(user._id);
                        setUserName(user.name);
                        setIsAdmin(user.isAdmin);
                        setLoggedInGoogle(user.googleid ? true : false);
                        if (!token)
                            cookies.set("cookieToken", user.cookieToken, {
                                expires: new Date("December 17, 2030 03:24:00"),
                            });
                    });
                });
            }
        });
    }, [socketConnected]);
    useEffect(function () {
        socket.on("connect", function () {
            post("/api/initsocket", { socketid: socket.id }).then(function () {
                setSocketConnected(true);
            });
        });
    }, []);
    useEffect(function () {
        if (!userId)
            return;
        socket.on("disconnect", function (reason) {
            setSocketConnected(false);
            console.log("SOCKET DISCONNECTED FOR THIS REASON " + reason);
            if (reason === "io server disconnect") {
                setForceDisconnected(true);
            }
            else {
                setTimeout(function () {
                    if (!socketConnected) {
                        window.location.reload();
                    }
                }, 2000);
            }
        });
    }, [userId]);
    var handleLogin = function (res) {
        var userToken = res.credential;
        var token = cookies.get("cookieToken");
        post("/api/googleLogin", { token: userToken, cookieToken: token || "no token" }).then(function (user) {
            posthog.identify(user.name);
            post("/api/initsocket", { socketid: socket.id }).then(function () {
                setUserId(user._id);
                setUserName(user.name);
                setIsAdmin(user.isAdmin ? true : false);
                setLoggedInGoogle(user.googleid ? true : false);
                if (!token)
                    cookies.set("cookieToken", user.cookieToken, {
                        expires: new Date("December 17, 2030 03:24:00"),
                    });
            });
        });
    };
    var handleLogout = function () {
        setUserId(undefined);
        post("/api/logout");
    };
    if (forceDisconnected)
        return _jsx(ForceDisconnected, {});
    if (!userId) {
        return _jsx(CircularProgress, {});
    }
    return (_jsx(_Fragment, { children: _jsxs(Router, { children: [_jsx(AdminPage, { path: "/admin", userId: userId, handleLogin: handleLogin, handleLogout: handleLogout, loggedInGoogle: loggedInGoogle, isAdmin: isAdmin }), _jsx(LobbyPage, { path: "/", handleLogin: handleLogin, handleLogout: handleLogout, loggedInGoogle: loggedInGoogle, userId: userId }), _jsx(RoomPage, { path: "/:roomName", userId: userId, userName: userName, setUserName: setUserName, loggedInGoogle: loggedInGoogle, handleLogin: handleLogin, handleLogout: handleLogout }), _jsx(Skeleton, { path: "/login", handleLogin: handleLogin, handleLogout: handleLogout, userId: userId }), _jsx(NotFound, { default: true })] }) }));
};
export default App;
