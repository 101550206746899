var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { post } from "../../utilities";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
var Chat = function (props) {
    var _a = useState(""), messageText = _a[0], setMessageText = _a[1];
    var _b = useState(new Date()), lastMessage = _b[0], setLastMessage = _b[1];
    var name = function (message) { return (_jsx("div", __assign({ style: {
            color: message.kind == "text" ? "#6c57f5" : "#333333",
            display: "inline",
            fontWeight: "900",
        } }, { children: message.userName }))); };
    var text = function (message) { return _jsx("div", __assign({ style: { display: "inline" } }, { children: ": " + message.text })); };
    return (_jsxs(Grid, __assign({ container: true, direction: "column" }, { children: [_jsx(Box, __assign({ display: "flex", flexDirection: "column-reverse", overflow: "auto", className: "chat", height: "calc(100vh - 240px)", paddingTop: "20px" }, { children: _jsx(List, { children: props.messages.map(function (message) { return (_jsx(ListItem, __assign({ dense: true }, { children: _jsxs(ListItemText, { children: [name(message), " ", text(message)] }) }), message._id)); }) }) })), _jsx(TextField, { label: "Message", variant: "outlined", size: "small", value: messageText, fullWidth: true, onChange: function (event) {
                    setMessageText(event.target.value);
                }, onKeyPress: function (event) {
                    if (event.key === "Enter") {
                        if (new Date().getTime() - new Date(lastMessage).getTime() >= 500) {
                            setLastMessage(new Date());
                            var body = {
                                roomId: props.roomId,
                                text: messageText,
                                kind: props.spectating ? "spectatorText" : "text",
                            };
                            post("/api/message", body).then(function () {
                                setMessageText("");
                            });
                        }
                    }
                } })] })));
};
export default Chat;
