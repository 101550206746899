var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { post } from "../../utilities";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Timer from "./Timer";
import QuestionBox from "./QuestionBox";
import Scores from "./Scores";
import Watchers from "./Watchers";
import QuestionsCarousel from "./QuestionsCarousel";
var RoomMain = function (props) {
    var startGame = function () {
        if (props.roomId === "")
            return;
        var body = { roomId: props.roomId };
        post("/api/startGame", body);
    };
    switch (props.status) {
        case "waiting":
            return (_jsxs(Grid, __assign({ container: true, direction: "column" }, { children: [_jsx(Timer, { endTime: new Date(), totalSeconds: 3, fixed: true }), _jsxs(Box, __assign({ marginLeft: "20px", marginRight: "20px" }, { children: [_jsx(Scores, { users: props.users, userId: props.userId }), _jsx(Watchers, { waitingPlayers: props.waitingPlayers, spectators: props.spectators }), _jsx(Button, __assign({ fullWidth: true, onClick: startGame }, { children: "Start Game" }))] }))] })));
        case "aboutToStart":
            return (_jsxs(Grid, __assign({ container: true, direction: "column" }, { children: [_jsx(Timer, { backwards: true, endTime: props.startTime, totalSeconds: 3 }), _jsxs(Box, __assign({ marginLeft: "20px", marginRight: "20px" }, { children: [_jsx(Scores, { scores: props.scores, users: props.users, userId: props.userId }), _jsx(Watchers, { waitingPlayers: props.waitingPlayers, spectators: props.spectators }), _jsx(Button, __assign({ disabled: true, fullWidth: true }, { children: "Start Game" }))] }))] })));
        case "inProgress":
            return (_jsxs(Grid, __assign({ container: true, direction: "column" }, { children: [_jsx(Timer, { endTime: props.startTime, totalSeconds: props.numSeconds }), _jsxs(Box, __assign({ marginLeft: "20px", marginRight: "20px" }, { children: [_jsx(QuestionBox, { question: props.question, guess: props.guess, skip: props.skip, spectating: props.spectating }), _jsx(Scores, { scores: props.scores, users: props.users, userId: props.userId }), _jsx(Watchers, { waitingPlayers: props.waitingPlayers, spectators: props.spectators })] }))] })));
        case "complete":
            return (_jsxs(Grid, __assign({ container: true, direction: "column" }, { children: [_jsx(Timer, { endTime: new Date(), totalSeconds: 3, fixed: true }), _jsxs(Box, __assign({ marginLeft: "20px", marginRight: "20px" }, { children: [_jsx(QuestionsCarousel, { questions: props.questionAnswers }), _jsx(Scores, { scores: props.scores, userId: props.userId, users: props.users, ratingUpdate: props.ratingUpdate }), _jsx(Watchers, { waitingPlayers: props.waitingPlayers, spectators: props.spectators }), _jsx(Button, __assign({ fullWidth: true, disabled: props.rematchDisabled, onClick: startGame }, { children: "Rematch" }))] }))] })));
    }
};
export default RoomMain;
