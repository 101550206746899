var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { GoogleLogin } from "@react-oauth/google";
//TODO(weblab student): REPLACE WITH YOUR OWN CLIENT_ID
var GOOGLE_CLIENT_ID = "180080882825-to464u7earqlme8o2muc5e0bqll8llih.apps.googleusercontent.com";
var Skeleton = function (props) {
    return (_jsxs(_Fragment, { children: [props.userId ? ("Logged In") : (_jsx(GoogleLogin, { onSuccess: props.handleLogin, onError: function () { return console.log("Login Failed"); } })), _jsx("h1", { children: "Good luck on your project :)" }), _jsx("h2", { children: " What you need to change in this skeleton" }), _jsxs("ul", { children: [_jsx("li", { children: "Change the Frontend CLIENT_ID (Skeleton.js) to your team's CLIENT_ID (obtain this at http://weblab.to/clientid)" }), _jsx("li", { children: "Change the Server CLIENT_ID to the same CLIENT_ID (auth.js)" }), _jsx("li", { children: "Change the Database SRV (mongoConnectionURL) for Atlas (server.js). You got this in the MongoDB setup." }), _jsx("li", { children: "Change the Database Name for MongoDB to whatever you put in the SRV (server.js)" })] }), _jsx("h2", { children: "How to go from this skeleton to our actual app" }), _jsx("a", __assign({ href: "https://docs.google.com/document/d/110JdHAn3Wnp3_AyQLkqH2W8h5oby7OVsYIeHYSiUzRs/edit?usp=sharing" }, { children: "Check out this getting started guide" }))] }));
};
export default Skeleton;
