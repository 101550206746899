var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
var UserInfo = function (props) {
    return (_jsxs(Box, __assign({ paddingLeft: "10px", paddingTop: "20px", marginBottom: "-16px" }, { children: [_jsx(Typography, __assign({ variant: "h5", component: "div", gutterBottom: true }, { children: props.name })), _jsxs(Grid, __assign({ container: true, direction: "row", width: "100%" }, { children: [_jsxs(Box, __assign({ width: "50%" }, { children: [_jsx(Typography, __assign({ sx: { mb: 0.5 }, color: "text.secondary" }, { children: "Rating" })), _jsx(Typography, __assign({ variant: "h5", component: "div", color: "#306AFF" }, { children: Math.round(props.rating) }))] })), _jsxs(Box, __assign({ width: "50%" }, { children: [_jsx(Typography, __assign({ sx: { mb: 0.5 }, color: "text.secondary" }, { children: "High Score" })), _jsx(Typography, __assign({ variant: "h5", component: "div", color: "#306AFF" }, { children: +props.highScore.toFixed(1) }))] }))] }))] })));
};
export default UserInfo;
