var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Progress } from "antd";
var Timer = function (props) {
    var _a = useState(100), percentage = _a[0], setPercentage = _a[1];
    useEffect(function () {
        if (props.totalSeconds === 0)
            return;
        if (new Date(props.endTime).getTime() <= new Date().getTime())
            return;
        var interval = setInterval(function () {
            var newPercentage = Math.min(100, Math.max(0, ((new Date(props.endTime).getTime() - new Date().getTime()) /
                props.totalSeconds /
                1000) *
                100));
            setPercentage(props.backwards ? newPercentage : 100 - newPercentage);
        }, props.totalSeconds < 5 ? 100 : 100);
        return function () {
            console.log("Ah cleared interval" + interval);
            clearInterval(interval);
        };
    }, [props.endTime, props.totalSeconds]);
    var color = "hsl(".concat(Math.floor((!props.fixed ? new Date().getTime() : 5400) / 50) % 360, ", 100%, 50%)");
    return (_jsx(Grid, __assign({ container: true, direction: "column", width: "100%", justifyContent: "center" }, { children: _jsx(Progress, { percent: percentage, showInfo: false, strokeLinecap: "butt", trailColor: "#616161", strokeColor: color, strokeWidth: 20, style: {
                marginTop: "-3px",
                marginBottom: "5px",
                transition: "all 0.75s ease",
            } }) })));
};
export default Timer;
